<template>
    <div class="w-full mt-5">
        <div class="grid grid-cols-2 gap-5 mb-0 w-full flex">
            <EpisodeHeader
                class="col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3"
                :episodes="episodes"
                :episode="computedEpisode"
                :tab="'EPISODES'"
                @episode-changed="episodeChanged"
            />
            <div class="col-span-2 lg:col-span-1 bg-white-pure l-box-shadow rounded self-auto">
                <v-tabs v-model="selectedTab">
                    <v-tab :key="0" class="text-lg">Program</v-tab>
                    <v-tab
                        v-for="(tab, index) in tabs"
                        :key="index + 1"
                        :ripple="false"
                        class="text-lg"
                    >
                        {{ tab.text }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="selectedTab">
                    <v-tab-item :key="0">
                        <ProgramSummary
                            class="px-4 py-3"
                            :users="users"
                            :episode="computedEpisode"
                            @update-episode="updateEpisode"
                        ></ProgramSummary>
                    </v-tab-item>
                    <v-tab-item :key="1">
                        <LevelDetail class="px-4 py-3" :episode="computedEpisode"></LevelDetail>
                    </v-tab-item>
                    <v-tab-item :key="2">
                        <TreatmentType
                            class="px-4 py-3"
                            :episode="computedEpisode"
                            @treatment-types-changed="treatmentTypesChanged"
                        ></TreatmentType>
                    </v-tab-item>
                </v-tabs-items>
            </div>
            <TreatmentProgress
                class="col-span-2 lg:col-span-1 self-auto bg-white-pure l-box-shadow rounded px-4 py-3"
            />
            <Forms
                class="col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3"
                :clientId="`${clientId}`"
                :episodeId="computedEpisode.episodeId"
            />
        </div>
    </div>
</template>

<script>
import EpisodeHeader from '@/components/episodes/partials/EpisodeHeader'
import ProgramSummary from '@/components/episodes/partials/ProgramSummary'
import TreatmentProgress from '@/components/episodes/partials/TreatmentProgress'
import Forms from '@/components/forms-list/FormsList'
import GET_CLIENT from '@/graphql/queries/getClient.gql'
import camelcaseKeys from 'camelcase-keys'
import LevelDetail from '@/components/level-detail/LevelDetail'
import TreatmentType from '@/components/episodes/partials/TreatmentType'
import GET_EPISODES_FOR_CLIENT from '@/graphql/queries/getEpisodesForClient.gql'
import {ClientHelpers} from '@/components/shared/mixins/clientMixins'
import {EpisodeHelpers} from '@/components/shared/mixins/episodeMixins'
import GET_USERS from '@/graphql/queries/getUsers.gql'
import {mapGetters, mapState} from 'vuex'
import * as snakecaseKeys from 'snakecase-keys'
import INSERT_EPISODE from '@/graphql/mutations/insertEpisode.gql'
import UPDATE_EPISODE from '@/graphql/mutations/updateEpisode.gql'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {formatISO} from 'date-fns'
import GET_ALL_CLIENTS from '@/graphql/queries/getAllClients.gql'
import {GET_YOUNG_PEOPLE} from '@/graphql/queries/userQueries.gql'

export default {
    name: 'Episodes',
    components: {
        Forms,
        TreatmentProgress,
        ProgramSummary,
        EpisodeHeader,
        LevelDetail,
        TreatmentType
    },
    mixins: [ClientHelpers, MessageDialog, EpisodeHelpers],
    apollo: {
        client: {
            query: GET_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({client}) => {
                return camelcaseKeys(client, {deep: true})
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        clients: {
            query: GET_YOUNG_PEOPLE,
            update: ({clients}) => {
                clients = camelcaseKeys(clients, {deep: true})
                return clients?.filter((client) => client.firstName !== '')
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        episodes: {
            query: GET_EPISODES_FOR_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({episodes}) => {
                episodes = camelcaseKeys(episodes, {deep: true})
                episodes.forEach((episode) => {
                    episode.locationProgramType = episode.locationProgram.locationProgramType.map(
                        (lpt) => lpt.type
                    )
                })

                const openEpisodes = episodes.filter((e) => e && e.status === 'OPEN')
                const otherEpisodes = episodes.filter((e) => e && e.status !== 'OPEN')
                return [...openEpisodes, ...otherEpisodes]
            }
        },
        users: {
            query: GET_USERS,
            update: ({users}) => {
                return camelcaseKeys(users, {deep: true})
            }
        }
    },
    data() {
        return {
            // currentEpisode: null,
            client: {},
            episodes: [],
            users: [],
            clientId: this.$route.params.clientId,
            selectedTab: 0,
            tabs: [
                {text: 'Level', component: 'LevelDetail', props: {}},
                {text: 'Treatment', component: 'TreatmentType', props: {}}
            ]
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', this.clientId)
        this.$root.$on('create-episode', this.createEpisode)
        this.$root.$on('close-episode', this.closeEpisode)
        this.$root.$on('open-episode', this.openEpisode)
        this.$root.$on('reopen-episode', this.reopenEpisode)
        this.$root.$on('submit-new-episode', this.submitNewEpisode)
        this.$root.$on('submit-close-episode', this.updateEpisode)
        this.$root.$on('submit-reopen-episode', this.updateEpisode)
        this.$root.$on('submit-set-to-referred', this.updateEpisode)
        this.$root.$on('set-to-referred', this.setToReferred)
        this.$root.$on('assessed-suitable', this.assessedSuitable)
        this.$root.$on('assessed-unsuitable', this.assessedUnsuitable)
        this.$root.$on('submit-assess-episode-suitable', this.updateEpisode)
        this.$root.$on('submit-assess-episode-unsuitable', this.updateEpisode)
        this.$root.$on('assessed-pending', this.assessedPending)
        this.$root.$on('submit-assess-episode-pending', this.updateEpisode)
        this.$root.$on('set-to-did-not-commence', this.setToDidNotCommence)
        this.$root.$on('submit-set-to-did-not-commence', this.updateEpisode)
        this.$root.$on('open-move-or-copy-episode', this.moveOrCopyEpisode)
        this.$root.$on('submit-move-episode', this.submitMoveEpisode)
        this.$root.$on('delete-episode', this.deleteEpisode)
        this.$root.$on('submit-delete-episode', this.handleDeleteEpisode)
        this.$root.$on('submit-copy-episode', this.submitCopyEpisode)
    },
    methods: {
        createEpisode() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                keepOpen: true
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalNewEpisode')
        },
        closeEpisode() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                keepOpen: true,
                episode: this.currentEpisode
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalCloseEpisode')
        },
        openEpisode() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                keepOpen: true,
                episode: this.currentEpisode
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalOpenEpisode')
        },
        reopenEpisode() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                keepOpen: true,
                episode: this.currentEpisode
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalReopenEpisode')
        },
        setToReferred() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                keepOpen: true,
                episode: this.currentEpisode
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSetToReferred')
        },
        assessedSuitable() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                keepOpen: true,
                episode: this.currentEpisode
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalAssessedSuitable')
        },
        assessedUnsuitable() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                keepOpen: true,
                episode: this.currentEpisode
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalAssessedUnsuitable')
        },
        assessedPending() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                keepOpen: true,
                episode: this.currentEpisode
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalAssessedPending')
        },
        episodeChanged(episode) {
            // this.$apollo.queries.episodes.refetch()
            // TODO: need to refetch the treatments and other stuff when this happens
            // this.currentEpisode = {...episode}
            this.$store.commit('SET_CURRENT_EPISODE', episode)
        },
        submitNewEpisode(data) {
            if (data.startDate) delete data.startDate
            const newStatus = this.addStatusChangeLog(data, 'SUBMIT')
            const newData = {...newStatus, ...data}
            const convertedData = snakecaseKeys(newData, {deep: true})
            this.$apollo
                .mutate({
                    mutation: INSERT_EPISODE,
                    variables: {
                        episodes: [convertedData]
                    }
                })
                .then(async ({data: {episodes}}) => {
                    if (episodes.returning && episodes.returning.length) {
                        await this.$apollo.queries.episodes.refetch()

                        // this.currentEpisode = episode.returning[0]
                        const episode = camelcaseKeys(episodes.returning[0], {deep: true})
                        if (!this.isEpisodeLocked(episode)) {
                            this.$store.commit('SET_CURRENT_EPISODE', episode)
                        }
                    }

                    this.saving = false
                    this.message = 'Episode was created'
                    this.type = 'success'
                })
                .catch((error) => {
                    this.message = 'Creating episode failed'
                    this.type = 'error'
                })
                .finally(() => {
                    this.saving = false
                    this.showMessage({duration: 5000})
                })
        },
        updateEpisode(data) {
            const messageText = data.messageText
            const newStatus = this.addStatusChangeLog(data.episode, 'UPDATE')
            const episode = {...data.episode, ...newStatus}
            if (episode.typename) delete episode.typename
            delete episode.worker
            if (episode.client) delete episode.client
            if (episode.locationProgram) delete episode.locationProgram
            if (episode.locationProgramType) delete episode.locationProgramType
            if (episode.status === 'DID_NOT_COMMENCE') delete episode.dateReferred
            episode.end_date = data.episode.endDate
            const convertedData = snakecaseKeys(episode, {deep: true})
            this.$apollo
                .mutate({
                    mutation: UPDATE_EPISODE,
                    variables: {
                        episodeId: episode.episodeId,
                        episode: convertedData
                    }
                })
                .then(() => {
                    this.saving = false
                    this.message = `${messageText}`
                    this.type = 'success'

                    this.$apollo.queries.episodes.refetch().then(() => {
                        this.$store.commit(
                            'SET_CURRENT_EPISODE',
                            this.episodes.find((ep) => ep.episodeId == episode.episodeId)
                        )
                    })
                })
                .catch((error) => {
                    this.message = `${messageText} failed`
                    this.type = 'error'
                })
                .finally(() => {
                    this.saving = false
                    this.showMessage({duration: 5000})
                })
        },
        treatmentTypesChanged(treatmentTypes) {
            const setConsultation = treatmentTypes.setConsultation
            delete treatmentTypes.setConsultation
            const convertedData = snakecaseKeys({treatmentTypes, setConsultation}, {deep: true})
            this.$apollo
                .mutate({
                    mutation: UPDATE_EPISODE,
                    variables: {
                        episodeId: this.currentEpisode.episodeId,
                        episode: convertedData
                    }
                })
                .then(({data: {episode}}) => {
                    this.$store.commit('SET_CURRENT_EPISODE', camelcaseKeys(episode, {deep: true}))

                    this.saving = false
                    this.message = 'Treatment type saved'
                    this.type = 'success'

                    this.$apollo.queries.episodes.refetch()
                })
                .catch((error) => {
                    this.message = 'Saving treatment types failed'
                    this.type = 'error'
                })
                .finally(() => {
                    this.saving = false
                    this.showMessage({duration: 5000})
                })
        },
        addStatusChangeLog(episode, action) {
            let result = {}
            const id = this.loggedInUser.userId
            if (action === 'SUBMIT') {
                result.client_id = this.clientId
            }
            if (episode.status) {
                const date = formatISO(new Date())
                if (episode.status === 'OPEN') {
                    result.start_date = formatISO(new Date(episode.startDate))
                    result.opened_by = id
                } else if (episode.status === 'SUITABLE') {
                    result.date_assessed_suitable = date
                    result.assessed_suitable_by = id
                } else if (episode.status === 'UNSUITABLE') {
                    result.date_assessed_unsuitable = date
                    result.assessed_unsuitable_by = id
                } else if (episode.status === 'PENDING') {
                    result.date_assessed_pending = date
                    result.assessed_pending_by = id
                } else if (episode.status === 'CLOSED') {
                    result.end_date = formatISO(new Date(episode.startDate))
                    result.closed_by = id
                } else if (episode.status === 'REFERRED') {
                    result.date_referred = date
                    result.set_referred_by = id
                } else if (episode.status === 'DID_NOT_COMMENCE') {
                    result.date_did_not_commence = date
                    result.set_did_not_commence_by = id
                    if (result.dateReferred) delete result.dateReferred
                }
            }
            return result
        },
        setToDidNotCommence() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                keepOpen: true,
                episode: this.currentEpisode
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalDidNotCommence')
        },
        moveOrCopyEpisode() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            const clients = this.clients.map((client) => {
                return {...client, text: this.getFullName(client), value: client.clientId}
            })
            this.$store.commit('MODAL_DATA', {
                name: this.getFullName(this.client),
                clientId: this.client.clientId,
                clients: clients,
                keepOpen: true,
                episode: this.currentEpisode,
                episodeForms: this.episodeFormsForCopy
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalMoveOrCopyEpisode')
        },
        submitMoveEpisode(data) {
            this.$store.commit(
                'SET_CURRENT_EPISODE',
                this.episodes.find((ep) => ep.episodeId == data.episode.episodeId)
            )
            this.$apollo.queries.episodes.refetch()
        },
        deleteEpisode() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalConfirmDeleteEpisode')
            this.$store.commit('MODAL_DATA', {episode: this.currentEpisode})
        },
        handleDeleteEpisode() {
            this.$apollo.queries.episodes.refetch()
        },
        submitCopyEpisode() {
            this.$apollo.queries.episodes.refetch()
        }
    },
    computed: {
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
            currentEpisode: (state) => state.app.currentEpisode,
            episodeFormsForCopy: (state) => state.app.episodeFormsForCopy
        }),
        ...mapGetters(['tenantId']),
        computedEpisode() {
            let checkEpisode = this.episodes?.find(
                (episode) => episode?.episodeId === this.currentEpisode?.episodeId
            )
            if (
                checkEpisode !== null &&
                this.episodes &&
                JSON.stringify(checkEpisode) !== JSON.stringify(this.currentEpisode)
            ) {
                return checkEpisode || {}
            }
            return this.currentEpisode || {}
        }
    },
    watch: {
        clientId() {
            console.log('clientId:', this.clientId)
            // TODO: add check if clientId is in store or not
        }
    },
    destroyed() {
        this.$root.$off('create-episode')
        this.$root.$off('close-episode')
        this.$root.$off('reopen-episode')
        this.$root.$off('open-episode')
        this.$root.$off('submit-new-episode')
        this.$root.$off('submit-close-episode')
        this.$root.$off('open-episode')
        this.$root.$off('reopen-episode')
        this.$root.$off('submit-reopen-episode')
        this.$root.$off('submit-set-to-referred')
        this.$root.$off('set-to-referred')
        this.$root.$off('assessed-suitable')
        this.$root.$off('submit-assess-episode-suitable')
        this.$root.$off('assessed-pending')
        this.$root.$off('submit-assess-episode-pending')
        this.$root.$off('set-to-did-not-commence')
        this.$root.$off('submit-set-to-did-not-commence')
        this.$root.$off('assessed-unsuitable')
        this.$root.$off('submit-assess-episode-unsuitable')
        this.$root.$off('open-move-or-copy-episode')
    }
}
</script>
