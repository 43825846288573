<template>
    <div class="treatment-type">
        <v-form
            ref="treatmentTypeForm"
            v-model="valid"
            class="mt-4"
        >
            <div class="p-4">
                <v-select
                    v-model="treatmentTypeForm.mainType"
                    label="Main Type"
                    :items="treatmentTypes"
                    placeholder="N/A"
                    @change="treatmentTypeFormChanged"
                ></v-select>
                <v-select
                    v-show="treatmentTypeForm.mainType !== 'ASSESSMENT_ONLY'"
                    v-model="treatmentTypeForm.otherTypes"
                    label="Other Types"
                    placeholder="N/A"
                    :items="otherTypes"
                    multiple
                    chips
                    deletable-chips
                    small-chips
                    @change="treatmentTypeFormChanged"
                ></v-select>
                <v-checkbox
                    v-model="treatmentTypeForm.setConsultation"
                    label="Consultation with Clinical Services Manager"
                    placeholder="N/A"
                    @change="treatmentTypeFormChanged"
                ></v-checkbox>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { DEFAULT_TREATMENT_TYPES } from '@/utils/constants'

export default {
    name: 'TreatmentType',
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            defaultTreatmentType: {
                ...DEFAULT_TREATMENT_TYPES,
                setConsultation: false
            },
            treatmentTypeForm: {},
            // treatmentTypes: ,
            valid: true,
            treatmentTypSubmitDebounce: null,
            treatmentTypSubmitDebounceTimer: 1000
        }
    },
    mounted() {
        this.treatmentTypeForm = { ...this.defaultTreatmentType, ...this.episode.treatmentTypes, setConsultation:  this.episode.setConsultation  }
    },
    methods: {
        treatmentTypeFormChanged() {
            if (this.treatmentTypeForm.mainType === 'ASSESSMENT_ONLY') {
                this.treatmentTypeForm.otherTypes = null
            } else if (this.treatmentTypeForm.otherTypes) {
                this.treatmentTypeForm.otherTypes = this.treatmentTypeForm.otherTypes.filter(type => type !== this.treatmentTypeForm.mainType)
            }

            if (this.treatmentTypSubmitDebounce) clearTimeout(this.treatmentTypSubmitDebounce)

            this.treatmentTypSubmitDebounce = setTimeout(() => {
                this.$emit('treatment-types-changed', this.treatmentTypeForm)
            }, this.treatmentTypSubmitDebounceTimer)
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
        }),
        otherTypes() {
            return this.treatmentTypes.filter(type => type.value !== 'ASSESSMENT_ONLY' && this.treatmentTypeForm.mainType && this.treatmentTypeForm.mainType !== type.value)
        },
        treatmentTypes() {
            return this.staticChoices['treatment.types']
        }
    },
    watch: {
        episode() {
            this.treatmentTypeForm = { ...this.defaultTreatmentType, ...this.episode.treatmentTypes, setConsultation: this.episode.setConsultation }
        }
    }
}
</script>

