<template>
    <div>
        <div class="flex flex-row justify-between pb-4 items-center">
            <div class="flex justify-between w-full">
                <h4 class="font-display font-semibold text-left text-lg">Forms</h4>
                <div class="flex flex-row">
                    <v-menu bottom>
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                                depressed
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2 v-btn--flat inline"
                                color="success"
                            >
                                <fa-icon icon="plus" class="mr-1" /> Add
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="item in episodeFormSets"
                                :key="item.formSetId"
                                @click="formSubmitHandler(item.formSetId)"
                            >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <div class="flex flex-col">
                        <v-btn
                            depressed
                            class="ml-2 v-btn--flat inline"
                            color="primary"
                            :class="{'disabled pointer-events-none': downloadingAssessmentSummary}"
                            @click.prevent="downloadAssessmentSummaryHandler"
                        >
                            <fa-icon
                                v-if="downloadingAssessmentSummary"
                                icon="spinner"
                                spin
                                class="mr-1"
                            />
                            <fa-icon v-else icon="download" class="mr-1" />
                            {{ downloadAssessmentBtnText }}
                        </v-btn>
                        <v-alert
                            v-model="showAlert"
                            border="right"
                            colored-border
                            elevation="2"
                            :closable="false"
                            :type="messageType"
                            class="text-sm ml-2 mt-1"
                            ><span class="mr-1">{{ message }}</span></v-alert
                        >
                    </div>
                </div>
            </div>
            <div class="text-right leading-4" v-if="episodeForms && episodeForms.length">
                <!--Temporarily hiding -->
                <!--
                <router-link
                    data-cy="editClient"
                    class="mr-4 group uppercase text-primary text-sm text-primary-light hover:text-primary"
                    :to="{name: 'edit-client', params: {clientId: clientId}}"
                >
                    <fa-icon icon="download" class="mr-1 group-hover:text-primary-light" />
                    <span class="font-semibold group-hover:text-primary-light">Assessment Summary</span>
                </router-link>
            -->

                <!-- Parked for MVP
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed
                      class="bg-white-pure"
                      v-bind="attrs"
                      v-on="on"
                    >
                        <fa-icon icon="plus" class="text-green mr-1" />
                        <span class="font-semibold uppercase text-green">Add new</span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in items"
                      :key="index"
                    >
                      <v-list-item-title class="text-grey-1">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
            --></div>
        </div>
        <div class="flex flex-row justify-between pb-4 items-center w-full" v-if="!loading">
            <div class="a-client-list w-full">
                <div class="flex flex-row">
                    <div class="flex items-center w-full">
                        <!-- TODO: add in filter -->
                        <FormTable
                            :table-data="tableData"
                            class="client-list"
                            @go-to-form-answer="goToFormAnswerHandler"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </div>
</template>

<script>
import {computed, ref} from '@vue/composition-api'
import camelcaseKeys from 'camelcase-keys'
import {storeToRefs} from 'pinia'
import FormTable from '@/components/forms-list/partials/FormTable'
import Loader from '@/assets/loader.svg'
import {FormBuilderMethods, FormRules} from '@/components/shared/mixins/formMixins'
import GET_FORM_ANSWER_STATS from '@/graphql/queries/getStatsForFormAnswers.gql'
import CREATE_FORM_ANSWER from '@/graphql/mutations/createFormAnswer.gql'
import {NOTHING_TO_DO} from '@/utils/constants'
import {mapActions, mapGetters, mapState} from 'vuex'
import {getStatus, percentage} from '@/components/shared/modules/forms'
import GET_EPISODE_FORMS from '@/graphql/queries/getEpisodeForms.gql'
import GET_FORM_SETS from '@/graphql/queries/getFormSets.gql'
import GET_FORM_SETS_FOR_EPISODE_TYPE from '@/graphql/queries/getFormSetsForEpisodeType.gql'
import GET_USERS from '@/graphql/queries/getUsers.gql'
import axiosInstance from '@/utils/axios'
import {downloadFile} from '@/utils/file.helpers'
import {useMessageStore} from '@/stores/useMessageStore'

export default {
    name: 'Forms',
    components: {FormTable, Loader},
    mixins: [FormBuilderMethods, FormRules],
    props: {
        clientId: {
            type: [String, Number],
            default: null
        },
        episodeId: {
            type: [String, Number],
            default: null
        }
    },
    apollo: {
        users: {
            query: GET_USERS,
            result({data: {users}}) {
                console.log('users', users)
                this.users = camelcaseKeys(users || [], {deep: true})
                this.foundUsers = true
            }
        },
        formSetsForEpisodeType: {
            query: GET_FORM_SETS_FOR_EPISODE_TYPE,
            variables() {
                return {
                    episodeTypeCompare: {
                        episode_type: {_contains: this.computedEpisode?.type}
                    }
                }
            },
            result({data: {formSetsForEpisodeType}}) {
                console.log('formSets by episode type', formSetsForEpisodeType)
                const formSetsByEpisode = camelcaseKeys(formSetsForEpisodeType, {deep: true})
                this.formSetList = this.filterFormSets(formSetsByEpisode)
                console.log('formSets', this.formSetList)
                // now we go and get the stats

                this.episodeFormSets = [...formSetsByEpisode]
            }
        },
        formSets: {
            query: GET_FORM_SETS,
            variables() {
                return {
                    formSetIds: this.formSetIds,
                    formTypeId: this.formType.formTypeId
                }
            },
            skip() {
                // if we skip this step, then we show the current list
                if (!this.episodeForms?.length || !this.formSetIds?.length)
                    this.formSetsLoading = false
                return !this.episodeForms?.length || !this.formSetIds?.length
            },
            result({data: {formSets}}) {
                const formSetList = camelcaseKeys(formSets || [], {deep: true})
                this.formSetList = this.filterFormSets(formSetList)
                this.formSetsLoading = false
            },
            watchLoading(isLoading, countModifier) {
                this.formSetsLoading = isLoading
            }
        },
        formAnswerStats: {
            query: GET_FORM_ANSWER_STATS,
            variables() {
                return {
                    tenantId: this.tenantId,
                    formAnswerIds: this.formAnswerIds
                }
            },
            skip() {
                if (!this.episodeForms.length) {
                    this.tableData = []
                    this.gettingFormAnswerStats = false
                    return true
                }
                return !this.foundUsers
            },
            result({data: {formAnswerStats}}) {
                const stats = formAnswerStats.stats
                console.log('formSets', stats)
                const keys = Object.keys(stats)
                const formSetList = [...this.formSetList]
                const tableData = [...this.episodeForms]
                tableData.forEach((td) => {
                    // we know we need to stop at this point
                    const stat = camelcaseKeys(stats[td.formAnswerId], {
                        deep: true,
                        stopPaths: ['all_scores.form.form_type.categories.scoring_categories']
                    })

                    td.hasScores = stat !== null && Object.keys(stat || {}).length > 0
                    if (!td.hasScores) return

                    td.percentDone = this.percentage(stat?.completedQuestions, stat?.maxQuestions)
                    td.updatedAt = stat?.updatedAt || ''
                    td.progress = stat?.completedQuestions
                    td.total = stat?.maxQuestions
                    td.status = this.getStatus(stat?.completedQuestions, stat?.maxQuestions)
                    td.dateCompleted = stat?.score?.createdAt
                    td.allScores = stat?.allScores
                    let user = this.users.find((u) => u.auth0Id === stat?.score?.completedById)
                    if (!user)
                        user = this.users.find((u) => u.userId === stat?.score?.completedById)
                    td.completedBy = user ? user.name : ''
                    td.completedById = stat?.score?.completedById

                    const idx = formSetList.findIndex((fsl) => fsl.formSetId === td.formSetId)
                    if (idx > -1) {
                        formSetList[idx].percentDone = this.percentage(
                            stat?.completedQuestions,
                            stat?.maxQuestions
                        )
                        formSetList[idx].updatedAt = stat?.updatedAt || ''
                        formSetList[idx].progress = stat?.completedQuestions
                        formSetList[idx].total = stat?.maxQuestions
                        formSetList[idx].status = this.getStatus(
                            stat?.completedQuestions,
                            stat?.maxQuestions
                        )
                        formSetList[idx].dateCompleted = stat?.score?.createdAt
                        formSetList[idx].completedBy = user ? user.name : ''
                        formSetList[idx].completedById = stat?.score?.completedById
                        formSetList[idx].hasScores = stat !== null && Object.keys(stat).length > 0
                        formSetList[idx].allScores = stat?.allScores
                    }
                })
                this.formSetList = [...formSetList]
                this.tableData = [...tableData]
                this.gettingFormAnswerStats = false
            }
        },
        episodeForms: {
            query: GET_EPISODE_FORMS,
            variables() {
                return {
                    episodeId: this.computedEpisode.episodeId,
                    tenantId: this.tenantId
                }
            },
            skip() {
                return (
                    this.computedEpisode === null ||
                    !Object.keys(this.computedEpisode).length ||
                    !this.computedEpisode.episodeId
                )
            },
            result({data: {episodeForms}}) {
                if (!episodeForms) return
                this.episodeFormsLoading = false
                this.episodeForms = camelcaseKeys(episodeForms, {deep: true})
            }
        }
    },
    data() {
        return {
            formSetList: [],
            items: [
                {title: 'Suicide Risk Screener'},
                {title: 'Client Satisfaction Survey'},
                {title: 'Click Me'},
                {title: 'Click Me 2'}
            ],
            addFormValid: true,
            episodeForms: [],
            episodeFormSets: [],
            formSetId: '',
            tableData: [],
            episodeFormSetAttachTo: '.assessment-select-wrapper',
            users: [],
            foundUsers: false,
            gettingFormAnswerStats: false,
            episodeFormsLoading: true,
            formSetsLoading: true,
            createFormAnswerLoading: false
        }
    },
    mounted() {
        this.$root.$on('submit-new-episode-form', this.submitNewEpisodeForm)
        this.$root.$on('submit-update-episode-form', this.submitNewEpisodeForm)
    },
    methods: {
        ...mapActions([
            'setEpisodeFormToUpdate',
            'setEpisodeFormToDelete',
            'setEpisodeFormsForCopy'
        ]),
        formSubmitHandler(formSetId) {
            if (!formSetId) return
            this.formSetId = formSetId
            this.openFormAddModal()
        },
        openFormAddModal() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                episodeId: this.episodeId,
                formSetId: this.formSetId,
                formSetName: this.episodeFormSets.find((set) => set.formSetId === this.formSetId)
                    .name,
                keepOpen: true
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalNewEpisodeForm')
        },
        async submitNewEpisodeForm(form) {
            await this.$store.commit('SET_FORM_TO_SAVE', form) // don't need 'deep: true' since there shouldn't be any nested objects
            const episodeForm = await this.$store.dispatch('createEpisodeForm')
            if (episodeForm === NOTHING_TO_DO) {
                return Promise.reject(`couldn't find an EpisodeForm to save`)
            }
            await this.$apollo.queries.episodeForms.refetch()
        },
        async saveEpisodeForm(form) {
            await this.$store.commit('SET_FORM_TO_SAVE', form) // don't need 'deep: true' since there shouldn't be any nested objects
            const episodeForm = await this.$store.dispatch('saveEpisodeForm')
            if (episodeForm === NOTHING_TO_DO) {
                return Promise.reject(`couldn't find an EpisodeForm to save`)
            }
            await this.$apollo.queries.episodeForms.refetch()
        },
        async updateFormAnswer(form) {
            await this.$store.commit('SET_FORM_TO_SAVE', form) // don't need 'deep: true' since there shouldn't be any nested objects
            const responseCode = await this.$store.dispatch('updateFormAnswer')
            if (responseCode === NOTHING_TO_DO) {
                return Promise.reject(`couldn't find an EpisodeForm to save`)
            }
            await this.$apollo.queries.episodeForms.refetch()
        },
        async deleteFormAnswer() {
            const responseCode = await this.$store.dispatch('deleteEpisodeForm')
            if (responseCode === NOTHING_TO_DO) {
                return Promise.reject(`couldn't find an EpisodeForm to save`)
            }
            await this.$apollo.queries.episodeForms.refetch()
        },
        getStatus: getStatus,
        percentage: percentage,
        filterFormSets(formSets) {
            const knownFormSetIds = this.formSetList.map((fs) => fs.formSetId)
            return [
                ...formSets.filter((fs) => !knownFormSetIds.includes(fs.formSetId)),
                ...this.formSetList
            ]
        },
        isSameSize(leftSet, rightSet) {
            return leftSet.size === rightSet.size
        },
        isSubSet(leftSet, rightSet) {
            if (leftSet.size > rightSet.size) return false

            for (let elem of leftSet) {
                // if any of the element of
                // this is not present in the
                // otherset then return false
                if (!rightSet.has(elem)) return false
            }
            return true
        },
        goToAssessment(formAnswerId, formSetData) {
            return this.$router.push({
                name: 'client-form',
                query: {fai: formAnswerId, t: formSetData.name},
                params: {
                    clientId: formSetData.clientId,
                    episodeId: formSetData.episodeId,
                    formSetId: formSetData.formSetId
                }
            })
        },
        goToFormAnswerHandler(formSetData) {
            this.createFormAnswerLoading = true

            const formSetId = formSetData?.formSetId
            const tenantId = this.tenantId
            const dueDate = formSetData?.dueDate

            let formAnswerId = null
            if (formSetData.formAnswerId)
                return this.goToAssessment(formSetData.formAnswerId, formSetData)

            if (formSetId) {
                const promise = formSetData?.formAnswerId
                    ? Promise.resolve({data: {formAnswer: formSetData}})
                    : this.$apollo.mutate({
                          mutation: CREATE_FORM_ANSWER,
                          variables: {
                              formSetId: formSetId,
                              tenantId: tenantId,
                              dueDate: dueDate
                          }
                      })

                promise
                    .then(async ({data: {formAnswer}}) => {
                        const converted = camelcaseKeys(formAnswer, {deep: true})
                        // not we need to save it against the EpisodeForm then we redirect
                        formAnswerId = converted.formAnswerId
                        await this.$store.commit('SET_FORM_TO_SAVE', {
                            episodeFormId: formSetData.episodeFormId,
                            episodeId: formSetData.episodeId,
                            formAnswerId,
                            formSetId,
                            formTitle: formSetData.title,
                            dueDate: formSetData.dueDate,
                            completedById: formSetData.completedById
                        }) // don't need 'deep: true' since there shouldn't be any nested objects
                        const episodeForm = await this.$store.dispatch('saveEpisodeForm')
                        if (episodeForm === NOTHING_TO_DO) {
                            return Promise.reject(`couldn't find an EpisodeForm to save`)
                        }

                        await this.$apollo.queries.episodeForms.refetch()
                        // TODO: maybe look at removing the title from the query and we get it from apollo - but for much later
                        this.goToAssessment(converted.formAnswerId, formSetData)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => {
                        this.createFormAnswerLoading = false
                    })
            } else {
                this.createFormAnswerLoading = false
            }
        }
    },
    computed: {
        ...mapGetters(['tenantId', 'formType', 'episodeFormToUpdate', 'episodeFormIdToDelete']),
        ...mapState({
            formType: (state) => state.app.formType,
            currentEpisode: (state) => state.app.currentEpisode
        }),
        formSetIds() {
            return this.episodeForms?.map((ef) => ef.formSetId)
        },
        formAnswerIds() {
            return this.episodeForms?.map((ef) => ef.formAnswerId).filter((fai) => fai)
        },
        computedEpisode() {
            return this.currentEpisode || {}
        },
        loading() {
            return (
                this.formSetsLoading ||
                this.episodeFormsLoading ||
                this.createFormAnswerLoading ||
                this.gettingFormAnswerStats
            )
        }
    },
    watch: {
        episodeId() {
            if (this.episodeId) {
                this.tableData = []
                this.$apollo.queries.episodeForms.refetch()
                this.$apollo.queries.formSets.refetch()
            }
        },
        episodeForms: {
            async handler() {
                this.setEpisodeFormsForCopy(this.episodeForms)
                if (this.gettingFormAnswerStats) return

                this.gettingFormAnswerStats = true
                await this.$apollo.queries.formAnswerStats.refetch()
            },
            deep: true
        },
        foundUsers() {
            if (this.foundUsers && !this.gettingFormAnswerStats) {
                this.gettingFormAnswerStats = true
                this.$apollo.queries.formAnswerStats.refetch()
            }
        },
        async episodeFormToUpdate() {
            console.log('EpisodeFormToUpdate')
            if (!this.episodeFormToUpdate) return

            await this.updateFormAnswer(this.episodeFormToUpdate)
            await this.setEpisodeFormToUpdate(null)
        },
        async episodeFormIdToDelete() {
            if (!this.episodeFormIdToDelete) return

            await this.deleteFormAnswer()
            await this.setEpisodeFormToDelete()
        }
    },
    destroyed() {
        this.$root.$off('submit-new-episode-form')
        this.$root.$off('submit-update-episode-form')
    },
    created() {
        this.$apollo.queries.formAnswerStats.refetch()
    },
    setup(props, {root}) {
        const $message = root.$message
        const showAlert = ref(false)
        // const message = ref('')
        // const messageType = ref('success')
        const messageStore = useMessageStore()
        const {message, type: messageType} = storeToRefs(messageStore)
        const {showMessage, resetMessage} = messageStore
        const downloadingAssessmentSummary = ref(false)
        const downloadAssessmentBtnText = computed(() => {
            return downloadingAssessmentSummary.value ? 'Downloading...' : 'Assessment Summary'
        })
        const downloadAssessmentSummaryHandler = async (event) => {
            showAlert.value = false
            message.value = ''
            messageType.value = 'success'
            downloadingAssessmentSummary.value = true
            // const endpoint = '/reports/client/:clientId/generate_asmt_summary_report/'
            const endpoint = `/reports/episode/${props.episodeId}/generate_asmt_summary_report/`
            // setTimeout(() => {
            //     downloadingAssessmentSummary.value = false
            //     message.value = 'Error downloading summary'
            //     messageType.value = 'error'
            //     showAlert.value = true
            //     setTimeout(() => {
            //         showAlert.value = false
            //     }, 5000)
            // }, 2000)

            try {
                const data = await axiosInstance.get(endpoint, {responseType: 'blob'})
                downloadFile(data)
                message.value = 'Downloaded Assessment Summary successfully.'
                messageType.value = 'success'
            } catch (error) {
                message.value =
                    'Failed to download report, please wait a few moments and try again.'
                message.value = 'Error downloading summary'
                messageType.value = 'error'
                showAlert.value = true
            } finally {
                downloadingAssessmentSummary.value = false
                showMessage({duration: 5000}, $message)
                // might be able to remove the showAlert
                showAlert.value = false
            }
        }

        return {
            message,
            showAlert,
            messageType,
            downloadAssessmentBtnText,
            downloadingAssessmentSummary,
            downloadAssessmentSummaryHandler
        }
    }
}
</script>
import { storeToRefs } from 'pinia'
