var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-level-detail__table editable_table flex flex-col w-full"},[_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableDataArr,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"footer-props":_vm.footerProps,"data-cy":"levelDetailList","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("startDate-" + (item.levelDetailId)),attrs:{"edit-active":item.active,"label":"Start Date","cell-id":item.levelDetailId,"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.startDate),callback:function ($$v) {_vm.$set(item, "startDate", $$v)},expression:"item.startDate"}})]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("endDate-" + (item.levelDetailId)),attrs:{"edit-active":item.active,"label":"End Date","cell-id":item.levelDetailId,"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.endDate),callback:function ($$v) {_vm.$set(item, "endDate", $$v)},expression:"item.endDate"}})]}},{key:"item.level",fn:function(ref){
var item = ref.item;
return [_c('SelectCell',{ref:("level-" + (item.levelDetailId)),attrs:{"edit-active":item.active,"label":"Level","items":_vm.levels,"isRequired":true,"cell-id":item.levelDetailId,"disabled":_vm.disabled,"displayValue":_vm.getDisplayValue(item.level, _vm.levels, 'text')},on:{"edit-row":_vm.toggleEdit},model:{value:(item.level),callback:function ($$v) {_vm.$set(item, "level", $$v)},expression:"item.level"}})]}},(!_vm.disabled)?{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"editable_table__edit-record ml-2 absolute text-center",class:{ 'active-tr': item.active}},[(!item.active)?_c('a',{staticClass:"mx-1 bg-red-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteHandler(item)}}},[_c('fa-icon',{staticClass:"text-red",attrs:{"icon":"trash-alt","data-cy":"deleteRecord"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"mr-1 bg-orange-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelHandler.apply(null, arguments)}}},[_c('fa-icon',{staticClass:"text-orange",attrs:{"icon":"times"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"hover:text-primary bg-primary-lightest",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.saveHandler(item)}}},[_c('fa-icon',{staticClass:"text-primary",attrs:{"icon":"check"}})],1):_vm._e()])]}}:null,(!_vm.disabled)?{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"mt-2 v-btn--flat inline",attrs:{"depressed":"","color":"success"},on:{"click":_vm.addLevelDetail}},[_c('fa-icon',{staticClass:"mr-1 group-hover:text-primary-light",attrs:{"icon":"plus"}}),_vm._v(" Add another ")],1)]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }