<template>
    <div class="">
        <div class="grid grid-cols-2 gap-4">
            <h4 class="font-display font-semibold text-left text-lg">Treatment Progress</h4>
            <div class="" v-show="hasData && !loadingGraph">
                <v-select
                    flat
                    clearable
                    :items="graphOptions"
                    v-model="selectedGraph"
                    label="Treatment type"
                    @change="updateGraph"
                ></v-select>
            </div>
        </div>

        <div v-show="hasData && !loadingGraph" id="chart-container">
            <div id="y-axis-title">Score</div>
            <div id="chart" class="w-full h-full">
                <div ref="progressChart" class="block relative w-full h-full"></div>
            </div>
            <div id="x-axis-title">Follow ups</div>
        </div>
        <img
            v-show="!hasData"
            src="@/assets/images/charts-coming.png"
            alt=""
            class="treatmentImage text-center mb-2 mt-2"
        />
        <div v-show="!hasData" class="text-center text-sm">{{ errorText }}</div>
    </div>
</template>

<script>
import {Chart} from 'frappe-charts/dist/frappe-charts.min.esm'
import {ref, watch, reactive, toRefs} from '@vue/composition-api'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import axiosInstance from '@/utils/axios'

const {useState} = createNamespacedHelpers('app')

export default {
    name: 'TreatmentProgress',
    setup() {
        const regionLineBuffer = 10
        const progressChart = ref(null)
        const chartSize = 250
        const maxValue = ref(50)
        const labels = ref([])
        const dataSet = ref([])
        const hasData = ref(true)
        const errorText = ref('')
        const todData = ref({})
        const state = reactive({
            loadingGraph: false
        })
        const {currentEpisode} = useState(['currentEpisode'])

        const selectedGraph = ref('K10')
        const graphOptions = ref([
            {
                label: 'K10',
                text: 'K10',
                value: 'K10'
            },
            {
                label: 'SDS',
                text: 'SDS',
                value: 'SDS'
            },
            {
                label: 'WHO-8',
                text: 'WHO-8',
                value: 'WHO-8'
            },
            {
                label: 'DSM-V',
                text: 'DSM-V',
                value: 'DSM-V'
            },
            {
                label: 'FAD',
                text: 'FAD',
                value: 'FAD'
            },
            {
                label: 'TOC',
                text: 'TOC',
                value: 'TOC'
            }
        ])

        const showProgressChart = () => {
            new Chart(progressChart.value, {
                data: {
                    labels: labels.value,
                    datasets: dataSet.value,
                    yRegions: [{label: 'Treatment', start: 0, end: maxValue.value}]
                },
                type: 'axis-mixed',
                height: chartSize.value,
                width: chartSize.value,
                colors: [
                    '#26A9E1' // tailwind: green
                ],
                lineOptions: {
                    regionFill: 0,
                    dotSize: 6
                },
                axisOptions: {
                    xAxisMode: 'tick',
                    yAxisMode: 'tick',
                    xIsSeries: true
                },
                tooltipOptions: {
                    formatTooltipY: (d) => 'Score: ' + d
                }
            })
        }

        const getTreatmentProgress = async () => {
            if (!currentEpisode.value?.episodeId) return
            state.loadingGraph = true
            const response = await axiosInstance.get(
                `/ted2_data.episode/${currentEpisode.value?.episodeId}/tod_graph/`
            )
            todData.value = response.data
        }

        const updateGraph = (val) => {
            const dataSetList = [
                {
                    label: val,
                    type: 'line',
                    values: []
                }
            ]

            const data = (todData.value?.[val] || []).reduce(
                (accum, item) => {
                    const {form, stage, score} = item
                    accum.labels.push(stage + '')
                    accum.dataSet[0].values.push(score)
                    return accum
                },
                {labels: [], dataSet: dataSetList}
            )
            labels.value = data.labels
            dataSet.value = data.dataSet
            const max = data.dataSet[0].values.length > 0 ? Math.max(...data.dataSet[0].values) : 0
            maxValue.value = (max ?? 50) + regionLineBuffer
            const isAllZero =
                data.dataSet[0]?.values?.every((item) => item === 0) || labels.length === 0
            hasData.value = !isAllZero
            errorText.value = isAllZero ? 'Oops, not enough data for Treatment Outcome!' : ''
            showProgressChart()
            state.loadingGraph = false
        }

        watch(
            () => currentEpisode.value?.episodeId,
            async (val) => {
                if (val) {
                    await getTreatmentProgress()
                    updateGraph(selectedGraph.value)
                }
            },
            {immediate: true}
        )

        return {
            ...toRefs(state),
            progressChart,
            selectedGraph,
            graphOptions,
            hasData,
            errorText,
            updateGraph,
            getTreatmentProgress,
            showProgressChart
        }
    }
}
</script>

<style>
#chart-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#x-axis-title {
    margin-top: -20px; /* Adjust the space below the chart */
    margin-bottom: 20px
}
#y-axis-title {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: left top;
    left: -10px; /* Adjust the distance from the chart */
    top: 50%;
}


</style>