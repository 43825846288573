<template>
    <div class="program-summary">
        <v-form
            ref="programDetailForm"
            v-model="isValid"
            class="mt-4"
        >
            <div class="grid grid-cols-2 gap-x-4">
                <div class="col-span-1">
                    <v-select
                        v-model="programDetailForm.locationProgramId"
                        label="Site - Section"
                        :items="siteSections"
                        disabled
                    ></v-select>
                </div>
                <div class="col-span-1">
                    <v-select
                        v-model="programDetailForm.type"
                        label="Episode Type"
                        :items="types"
                        disabled
                    ></v-select>
                </div>
                <div class="col-span-1">
                <!--Date range picker, end date does not have to be set-->
                    <EditableDatePicker
                        v-model="programDetailForm.startDate"
                        :placeholder="'N/A'"
                        :label="'Program Start'"
                        :disabled="(programDetailForm.status === 'CLOSED' || programDetailForm.status === 'OPEN') ? false : true"
                        :disableFutureDate="true"
                        :rules="[futureDateNotAllowed, validProgramStart]"
                        @changed="programDetailFormChanged"
                    />
                </div>
                <div class="col-span-1" v-if="programDetailForm.status === 'CLOSED'">
                    <EditableDatePicker
                        v-model="programDetailForm.endDate"
                        :placeholder="'N/A'"
                        :label="'Program End'"
                        disabled
                    />
                        <!-- ^ I couldnt make this disabled work-->
                </div>
                <!-- Outcome should only display as a locked field IF the episode status == Progam closed
                The form field only gets saved when a user clicks 'close program' & adds outcome to the modal -->
                <div class="col-span-1" v-if="programDetailForm.status === 'CLOSED'">
                    <v-select
                        v-model="programDetailForm.outcome"
                        label="Outcome"
                        :items="outcomes"
                        disabled
                    ></v-select>
                </div>
                <div class="col-span-1" v-if="programDetailForm.status === 'CLOSED'">
                    <v-select
                        v-model="programDetailForm.reason"
                        label="Reason"
                        :items="reasons"
                        disabled
                    ></v-select>
                </div>
                <div class="col-span-1" v-if="programDetailForm.status === 'CLOSED'">
                    <v-select
                        v-model="programDetailForm.progress"
                        label="Progress"
                        :items="progressOptions"
                        disabled
                    ></v-select>
                </div>
                <div class="col-span-1" v-if="programDetailForm.status === 'CLOSED'">
                    <v-select
                        v-model="programDetailForm.service"
                        label="Referral to Another Service"
                        :items="referralServices"
                        disabled
                    ></v-select>
                </div>
                <div class="col-span-1">
                    <v-autocomplete
                        ref="worker"
                        :items="workersByLocationProgram"
                        :disabled="disabled"
                        color="white"
                        label="Worker"
                        :value="programDetailForm.userId"
                        @input="programDetailForm.userId = $event || defaultWorker.userId"
                        @change="programDetailFormChanged"
                    ></v-autocomplete>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { FormEvents, FormRules } from '@/components/shared/mixins/formMixins'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import { format, isValid, parse, parseISO } from 'date-fns'
import EditableDatePicker from '@/components/shared/EditableDatePicker'

export default {
    name: 'ProgramSummary',
    components: { EditableDatePicker },
    mixins: [ FormRules, FormEvents, ClientHelpers ],
    props: {
        episodeStatus:  {
            type: String,
            default: ''
        },
        users: {
            type: Array,
            default: () => []
        },
        episode: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            clientId: '',
            isValid: true,
            defaultProgramDetail: {
                type: 'RESIDENTIAL',
                siteSection: '1-2',
                startDate: format(new Date(), 'yyyy-MM-dd'),
                endDate: null,
                locationProgramId: null,
                worker: 1234,
            },
            defaultWorker: {
                firstName: '',
                lastName: '',
                name: '',
                typeName: '',
                userId: null
            }
        }
    },
    mounted() {
        this.setDates()
        if (this.programDetailForm.worker) this.defaultWorker = this.programDetailForm.worker
    },
    methods: {
        // function for filter v-autocomplete
        customFilter (item, queryText, itemText) {
            const textOne = item.firstName ? item.firstName.toLowerCase() : ''
            const textTwo = item.lastName ? item.lastName.toLowerCase() : ''
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
        },
        programDetailFormChanged() {
            if(this.programDetailForm.userId === this.defaultWorker.userId) this.$refs.worker.setValue(this.defaultWorker.userId)
            if(this.programDetailForm.userId !== this.defaultWorker.userId || this.startDate !== this.programDetailForm.startDate ) this.$emit('update-episode', { messageText: 'Update episode', episode: { ...this.programDetailForm } })
        },
        setDates() {
            this.startDate = this.programDetailForm.startDate
            this.endDate = this.programDetailForm.endDate
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections,
            episodeTypes: state => state.app.episodeTypes
        }),
        outcomes() {
            return this.staticChoices['programDetail.outcome'] || []
        },
        referralServices() {
            return this.staticChoices['programDetail.referralService'] || []
        },
        reasons() {
            return this.staticChoices['programDetail.reason'] || []
        },
        progressOptions() {
            return this.staticChoices['programDetail.progress'] || []
        },
        types() {
            return this.episodeTypes.map(episodeType => {
                return {
                    value: episodeType.code,
                    text: episodeType.name
                }
            })
        },
        programDetailForm: {
            get() {
                if (this.episode.worker) {
                    return {
                        ...this.episode,
                        userId: this.episode.worker.userId
                    }
                }
                return this.episode
            },
            set(programDetailForm) {
                if (this.programDetailForm.worker) this.defaultWorker = this.programDetailForm.worker
                if (this.programDetailForm.progress == "HIGH") this.programDetailForm.progress = 'MODERATE'
                console.log('programDetailForm', programDetailForm)
            }
        },
        workersByLocationProgram() {
            if (!this.programDetailForm.locationProgramId) return this.workersByLoggedInUser

            return this.workersByLoggedInUser.filter(worker => worker.locationProgramIds.includes(this.programDetailForm.locationProgramId))
        },
        disabled() {
            return !this.loggedInUser.locationProgramIds.includes(this.programDetailForm.locationProgramId)
        }
    },
    watch: {
        siteSections() {
            console.log('siteSections', this.siteSections)
        },
        startDate() {
            this.programDetailForm.startDate = this.startDate
        },
        endDate() {
            this.programDetailForm.endDate = this.endDate
        },
        programDetailForm(data) {
            if (this.programDetailForm.status === "OPEN") {
                data.startDate = format(new Date(data.startDate), 'yyyy-MM-dd')
            } else if (this.programDetailForm.status === "REFERRED") {
                data.startDate = null
            }
            this.programDetailForm = data
            this.setDates()
        }
    }
}
</script>
