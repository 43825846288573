<template>
    <LevelDetailTable
        :tableData="levelDetails"
        :episode="episode"
        @level-detail-mutated="refreshLevelDetails"
    />
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import LevelDetailTable from '@/components/level-detail/partials/LevelDetailTable'
import GET_LEVEL_DETAILS_FOR_EPISODE from '@/graphql/queries/getLevelDetailsForEpisode.gql'

export default {
    name: 'LevelDetail',
    components: { LevelDetailTable },
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        levelDetails: {
            query: GET_LEVEL_DETAILS_FOR_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.episode.episodeId),
                }
            },
            skip() {
                if (!this.episode || !this.episode.episodeId) {
                    this.levelDetails = []
                    return true
                }
                return false
            },
            result({ data: { levelDetails } }) {
                let convertedLevelDetails = camelcaseKeys(levelDetails, {deep: true})
                this.levelDetails = [...convertedLevelDetails]
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    data() {
        return {
            levelDetails: []
        }
    },
    methods: {
        refreshLevelDetails() {
            this.$apollo.queries.levelDetails.refetch()
        }
    }
}
</script>
