<template>
    <div class="c-level-detail__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :footer-props="footerProps"
            class="a-table w-full"
            data-cy="levelDetailList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="rowClickHandler"
        >
            <template class="" v-slot:item.startDate="{ item }">
                <DatePickerCell
                    :ref="`startDate-${item.levelDetailId}`"
                    :edit-active="item.active"
                    label="Start Date"
                    v-model="item.startDate"
                    :cell-id="item.levelDetailId"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.endDate="{ item }">
                <DatePickerCell
                    :ref="`endDate-${item.levelDetailId}`"
                    :edit-active="item.active"
                    label="End Date"
                    v-model="item.endDate"
                    :cell-id="item.levelDetailId"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.level="{ item }">
                <SelectCell
                    :ref="`level-${item.levelDetailId}`"
                    :edit-active="item.active"
                    label="Level"
                    v-model="item.level"
                    :items="levels"
                    :isRequired="true"
                    :cell-id="item.levelDetailId"
                    :disabled="disabled"
                    :displayValue="getDisplayValue(item.level, levels, 'text')"
                    @edit-row="toggleEdit"
                />
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record ml-2 absolute text-center" :class="{ 'active-tr': item.active}">
                    <a
                        href="#"
                        @click.prevent="deleteHandler(item)"
                        v-if="!item.active"
                        class="mx-1 bg-red-light"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange"/>
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="saveHandler(item)"
                        class="hover:text-primary bg-primary-lightest"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-btn
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addLevelDetail"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { FormRules } from '@/components/shared/mixins/formMixins'
import EditableCell from '@/components/partials/EditableCell'
import NumberInputCell from '@/components/partials/NumberInputCell'
import DatePickerCell from '@/components/partials/DatePickerCell'
import INSERT_LEVEL_DETAIL from '@/graphql/mutations/insertLevelDetail.gql'
import DELETE_LEVEL_DETAIL from '@/graphql/mutations/deleteLevelDetail.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import SelectCell from '@/components/partials/SelectCell'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { format } from 'date-fns'

export default {
    name: 'LevelDetailTable',
    components: { DatePickerCell, SelectCell, NumberInputCell, EditableCell },
    mixins: [ FormRules, MessageDialog ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        episode: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            emptyText: 'No level details found',
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 3,
            page: 1,
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            footerProps: {
                'items-per-page-options': [3]
            },
            headers: [
                // { text: 'No', align: 'left', value: 'order', class: 'order--header w-50' },
                { text: 'Start Date', align: 'left', value: 'startDate', class: 'w-200 start-date--header' },
                { text: 'End Date', align: 'left', value: 'endDate', class: 'w-200 end-date--header' },
                { text: 'Level', align: 'left', value: 'level', class: 'w-100 level--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            sortBy: [],
            sortDesc: [true],
            refsToCheck: [
                // 'order',
                'startDate',
                'endDate',
                'level'
            ],
            levels: [
                {
                    text: 'Level 1',
                    value: 'LEVEL_1'
                },
                {
                    text: 'Level 2',
                    value: 'LEVEL_2'
                },
                {
                    text: 'Level 3',
                    value: 'LEVEL_3'
                },
                {
                    text: 'Level 4',
                    value: 'LEVEL_4'
                },
                {
                    text: 'Graduation',
                    value: 'GRADUATION'
                }
            ],
            defaultLevelDetail: {
                order: null,
                startDate: format(new Date(), 'yyyy-MM-dd'),
                endDate: null,
                level: null
            },
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
        if (!this.tableDataArr.length) this.addLevelDetail()
    },
    methods: {
        rowClickHandler(obj, e) {
            this.currentRow = obj
            e.select(true)
        },
        addLevelDetail() {
            // if we already have a new behaviour Incident, then we stop the function
            if (this.currentNewId) return
            console.log('adding new level detail row')

            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempLevelDetail = {
                levelDetailId: this.currentNewId,
                episodeId: this.episode.episodeId,
                userId: this.loggedInUser.userId,
                ...this.defaultLevelDetail
            }
            this.tableDataArr.push(tempLevelDetail)

            this.page = parseInt((this.tableDataArr.length - 1) / 3) + 1
        },
        getDisplayValue(val, displayList, prop = 'label') {
            if (!displayList || displayList.length === 0 || !val) return ''

            const displayValue = displayList.find(t => t.value.toLowerCase() === val.toLowerCase())
            return displayValue != null ? displayValue[prop] : ''
        },
        toggleEdit(val) {
            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            this.message = `Please save/discard the ${isEdit ? 'editing' : 'new'} row before moving to other rows.`
            this.showMessage({duration: 1500})
        },
        isActiveRowChanged() {
            // const fields = ['order', 'goal', 'commenced', 'completed']
            const fields = ['goal', 'commenced', 'completed']
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj) return false

            return fields.filter(field => this.activeRowObj[field] != changedObj[field]).length > 0
        },
        getActiveRow(data) {
            const activeRow = data.find((item) => {
                return item.levelDetailId === this.activeRowId
            })
            return {...activeRow}
        },
        validateInputs(id) {
            const forms = this.refsToCheck.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {
                    // Post back to db
                    // this.mutationCall(this.getActiveRow(this.tableDataArr), false)

                    const data = this.getActiveRow(this.tableDataArr)
                    delete data.updated
                    const idx = this.tableDataArr.findIndex(t => t.levelDetailId === data.levelDetailId)

                    if (data && data.typename) delete data.typename
                    if (this.currentNewId && this.currentNewId === this.activeRowId) delete data.levelDetailId

                    delete data.active
                    delete data.created
                    const convertedData = snakecaseKeys(data, {deep: true})
                    this.$apollo.mutate({
                        mutation: INSERT_LEVEL_DETAIL,
                        variables: {
                            levelDetail: convertedData
                        }
                    }).then(({ data: { levelDetail } }) => {
                        console.log('level detail returned', levelDetail)
                        if (levelDetail) {
                            this.tableDataArr[idx] = {...camelcaseKeys(levelDetail, {deep: true})}
                        }

                        this.saving = false
                        this.message = 'Saved level detail item'
                        this.type = 'success'

                        this.activeRowId = null
                        this.currentNewId = null
                        this.activeRowObj = null

                        this.$emit('level-detail-mutated')
                    }).catch(error => {
                        this.message = 'Failed saving level detail'
                        this.type = 'error'
                    }).finally(() => {
                        this.saving = false
                        data.active = true
                        this.showMessage({ duration: 5000 })
                    })
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.levelDetailId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            if(!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.levelDetailId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.levelDetailId !== this.activeRowId) return item
                })
            }
        },
        deleteHandler(item) {
            this.clearRowSelection()
            this.tableDataArr = this.tableDataArr.filter(td => {
                if (td.levelDetailId !== item.levelDetailId) return td
            })
            this.$apollo.mutate({
                mutation: DELETE_LEVEL_DETAIL,
                variables: {
                    levelDetailId: item.levelDetailId
                }
            }).then(({ data: { levelDetail } }) => {
                console.log('level detail returned', levelDetail)

                this.saving = false
                this.message = 'Deleted level detail'
                this.type = 'success'

                this.activeRowId = null
                this.currentNewId = null
                this.activeRowObj = null

                this.$emit('level-detail-mutated')
            }).catch(error => {
                this.message = 'Failed deleting level detail'
                this.type = 'error'
            }).finally(() => {
                this.saving = false
                this.showMessage({ duration: 5000 })
            })
        },
        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices
        }),
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
            if (!this.tableDataArr.length) this.addLevelDetail()
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        }
    }
}
</script>
